import React, { useRef } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'
import InnerLink from '../components/Typography/InnerLink'
import MediumImage from '../components/Image/MediumImage'
// import SectionStyle1 from '../components/curves/SectionStyle1'
import SEO from '../layouts/Seo'
import ImgWrapper from '../components/Image/ImgWrapper'
import ImgLightBox from '../components/lightbox/ImgLightBox'
import GeneralCarousel from '../contents/carousel/GeneralCarousel'
import KareGozluTablo from '../contents/tables/KareGozluTablo'
import DikdortgenGozluTablo from '../contents/tables/DikdortgenGozluTablo'
import OlcuAlert from '../contents/alert/OlcuAlert'
import HasirTelKullanim from '../contents/common/HasirtelKullanim'
const PuntaKaynakliTel = () => {
  const { getImageByName } = useStore()
  const lightBoxRef = useRef()
  return (
    <>
      <SEO
        title='Punta Kaynaklı Hasır Tel - Erez Kaynak'
        description='Punta Kaynaklı Hasır Tel Firmamız siyah ham çelik tel, galvaniz telden yada 304-316 kalite paslanmaz malzeme kullanarak punta kaynaklı hasır tel imalatı yapmaktadır.Galvaniz yada siyah ham punta kaynaklı hasır teller ham malzeme olduğu için sonradan fırın boya yada daldırma boya ile boyanabilir.'
        keywords='Punta Kaynaklı Hasır Tel,kafes tel,tel kafes,hasır tel,punta kaynaklı hasır tel kullanım alanları,punta kaynaklı hasır tel istanbul,punta kaynaklı kafes tel,punta kaynaklı tel kafes,punta kaynaklı hasır tel,kafes tel fiyatları,hasır tel fiyatları,tel kafes fiyatları,50x50x5 Punta Kaynaklı Hasır Tel,punta kaynaklı hasır tel fiyatları,punta kaynaklı kafes tel fiyatları'
        url='punta-kaynakli-hasir-tel'
        imgurl={getImageByName('telkafes').sm.src}
      />
      <ImgLightBox ref={lightBoxRef} gallery={[getImageByName('telkafes').original.src]} />
      <Box paddingX={3} paddingY={5}>
        <Grid container spacing={6} alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <ContentHeader title='Punta Kaynaklı Hasır Tel' description='Türkiyenin her yerine gönderim yapılmaktadır.' />
            <ContentText>
              <p>
                Firmamız <strong>siyah ham çelik tel, galvaniz telden</strong> ya da <strong>304-316 kalite paslanmaz</strong> malzeme
                kullanarak <strong>punta kaynaklı hasır tel</strong> imalatı yapmaktadır.
              </p>
              <p>
                <strong>Galvaniz ya da siyah ham punta kaynaklı hasır teller</strong> ham malzeme olduğu için sonradan fırın boya ya da
                daldırma boya ile boyanabilir.
              </p>
              <p>
                Tel kalınlığı 2mm den 8mm tel kalınlığına kadar <strong>punta kaynaklı hasır tel</strong> imalatını yapabiliyoruz.
              </p>
              <p>
                Göz aralığı standart olarak merkezden merkeze 15mm, 20mm, 25mm ve katlarında <strong>punta kaynaklı hasır tel</strong>{' '}
                imalatı yapılabilir.
              </p>
              <p>
                15mm katı olan 15x90mm veya 25mm katı olan 50x125mm gibi istediğiniz her ölçüde <strong>punta kaynaklı hasır tel</strong>{' '}
                yapabilmekteyiz.
              </p>
              <p>
                Özel göz aralıklarında (örn; 26,3×37,5mm vb.) yeni kalıp yaparak hızlı bir şekilde müşterilerimizin ihtiyacı olan{' '}
                <strong>punta kaynaklı hasır tel</strong> uygun bir maliyet ile üretiyoruz.
              </p>
              <p>Detaylı bilgi yada fiyat teklifi almak için bizi arayınız.</p>
            </ContentText>
            <Box textAlign='center'>
              <InnerLink title='İletişim Bilgilerimiz İçin Tıklayınız' url='/iletisim' />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <ImgWrapper onClick={() => lightBoxRef.current.openLightBox()}>
              <MediumImage lazyHeight={500} img={getImageByName('telkafes')} alt='Özel hasır tel imalatı' />
            </ImgWrapper>
            <Box mt={2}>
              <MediumImage lazyHeight={500} img={getImageByName('celikhasirolculeri')} alt='özel göz hasır tel imalatı erez kaynak' />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <GeneralCarousel />

      <HasirTelKullanim title='Punta Kaynaklı Hasır Tel Kullanım Alanları' />

      <Box p={3}>
        <OlcuAlert />
      </Box>
      <Box p={3} mb={5}>
        <KareGozluTablo />
      </Box>
      <Box p={3} mb={5}>
        <DikdortgenGozluTablo />
      </Box>
    </>
  )
}

export default PuntaKaynakliTel
